import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import g20 from '../images/g20.png'
import meity from '../images/meity.png'

export default function NavBarHome(props) {

    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();

        navigate('/login')
    }


    return (
        <>
            <nav className="navbar  navbar-expand-lg " >

                <div className="container-fluid">

                    <a className="navbar-brand ms-5" href="#">{props.title}</a>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" ></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto pe-3">

                            {/* <li className="nav-item">
            <Link className="nav-link " to="/about">About</Link>
        </li> */}

                            {/* <li className="nav-item">
                                <a className="a nav-link " href="#" onClick={handleClick}>Login</a>
                            </li> */}
                        </ul>
                    </div>

                </div>
            </nav>
            <nav className="user navbar  navbar-expand-lg  text-center p-3  "  >
                <div className="container-fluid ">
                    <a href="https://www.meity.gov.in/" target="_blank">
                        <img className='responsive ' style={{ marginRight: 5, cursor: 'pointer' }} src={meity} alt="meity logo"></img>
                    </a>
                    <div className="collapse navbar-collapse " id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0 ">
                            <li className="nav-item ">

                            </li>
                            <li className="nav-item ">
                                <Link className="nav-link" aria-current="page" to="/"><strong>Home</strong></Link>
                            </li>
                            <li className="nav-item ">
                                <Link className="nav-link" aria-current="page"
                                      to="/objective"><strong>Objective</strong></Link>
                            </li>
                            <li className="nav-item">
                                {/* <Link className="nav-link" aria-current="page" to="/homegallery">GALLERY</Link> */}
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/news"><strong>News and Notice</strong></Link>
                            </li> */}
                            <li className="nav-item">

                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                   aria-expanded="false">
                                    <strong>View Designs/Motifs</strong>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                    <li><Link className="dropdown-item" to="/viewDesigns">View Designs</Link></li>
                                    <li><Link className="dropdown-item" to="/viewMotifs">View Motifs</Link></li>
                                    {/* <li><Link className="dropdown-item" to="/viewmapBeneficiary">Map Beneficiary</Link></li> */}
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                   aria-expanded="false">
                                    <strong>Login</strong>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                    <li><Link className="dropdown-item" to="/Login">Login as Employee</Link></li>
                                    <li><Link className="dropdown-item" to="/LoginUser">Login as User</Link></li>
                                    {/* <li><Link className="dropdown-item" to="/viewmapBeneficiary">Map Beneficiary</Link></li> */}
                                </ul>
                            </li>

                            {/* <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/feedback">Feedback</Link>
                            </li> */}
                            {/* <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/register">Register</Link>
                            </li> */}
                        </ul>
                    </div>
                    <a href="https://g20.mygov.in/" target="_blank">
                        <img className='responsive ' style={{marginLeft: 5}} src={g20} alt="g20 logo"></img>
                    </a>
                </div>
            </nav>
        </>
    )
}
