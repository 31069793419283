import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import config from '../config.json'

import { Link, Navigate, useNavigate } from 'react-router-dom'

import { decrypter } from "../Util"

import { useTheme } from '@mui/material/styles';
import Banner1 from '../images/banner1.jpg'

import axios from 'axios';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
    DataGrid, GridToolbar,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import { Grid } from "@mui/material";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import TextField from "@mui/material/TextField";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import ButtonBase from '@mui/material/ButtonBase';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import { Tooltip } from '@mui/material';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import makeStyles from '@mui/styles/makeStyles';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import moment from 'moment';
import regex from "../regex.json"
import NavBarMeity from "../NavBar/NavBarMeity";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import InfoIcon from '@mui/icons-material/Info';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ImageZoom from "react-image-zooom";
import ImageListMotifMeity from "../Meity/ImageListMotifMeity";
import MenuItem from "@mui/material/MenuItem";
import Pagination from '@mui/material/Pagination';
import TablePagination from '@mui/material/TablePagination';
import NavBarHome from "../NavBar/NavBarHome";



const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;
const SECRET = config.SECRET;

const style2 = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
}

const useStyle = makeStyles({
    root: {
        "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center",
            fontWeight: 'bold'
        },
        "& .MuiDataGrid-cellContent": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center"
        },
        "& .MuiTypography-string": {
            textOverflow: "ellipsis",
            whiteSpace: "break-spaces",
            lineHeight: 2,
            textAlign: "center"
        },
        boxShadow: 2,
        '& .MuiTablePagination-selectLabel': {
            marginTop: 'auto'
        },
        '& .MuiTablePagination-displayedRows': {
            marginTop: 'auto'
        }
    }
});

const useStylesCard = makeStyles({
    root: {

    },
    media: {
        "&:hover": {
            transform: "scale(3.5)"
        }
    }
});

function ViewMotifs(props) {
    const navigate = useNavigate()
    // let cookiedata = decrypter(cookies.get("sessionid"))


    // let userdata = cookiedata.userdata
    // let jwt = cookiedata.token

    // let role = cookiedata.userdata.user.roles.map(op => op.role_name)

    // let userrole = role[0]

    // let KEY = 'Bearer ' + jwt;

    const classes = useStyle()
    const classescard = useStylesCard()

    const [refresh, setrefresh] = useState(0)

    const [motifs, setmotifs] = useState([])

    const [fmotifs, setfmotifs] = useState([])

    const [centers, setcenters] = useState([])

    const [viewformModal, setviewformModal] = useState(false)

    const [viewimgModal, setviewimgModal] = useState(false)
    const [viewoption, setviewoption] = useState([])

    const [loading, setloading] = useState(true)
    const [pageSize, setPageSize] = useState(10);

    const [motifid, setmotifid] = useState('')
    const [cat, setcat] = useState('')
    const [subcat, setsubcat] = useState('')
    const [height, setheight] = useState('')
    const [width, setwidth] = useState('')
    const [hooks, sethooks] = useState('')
    const [ppi, setppi] = useState('')
    const [epi, setepi] = useState('')
    const [color, setcolor] = useState('')
    const [dentinch, setdentinch] = useState('')
    const [dentorderid, setdentorderid] = useState('')
    const [weaves, setweaves] = useState('')
    const [uploadedby, setuploadedby] = useState('')
    const [filename, setfilename] = useState('')
    const [status, setstatus] = useState('')
    const [remarks, setremarks] = useState('')
    const [viewMovementModal, setviewMovementModal] = useState(false)
    const [movementarr, setmovementarr] = useState([])

    const [us, setus] = useState('')
    const [zoom, setzoom] = useState(false)


    const [openapprovedialog, setopenapprovedialog] = useState(false)
    const [rejectreason, setrejectreason] = useState('')
    const [errrejectreasonb, seterrrejectreasonb] = useState(false)
    const [errrejectreason, seterrrejectreason] = useState('')
    const [openrejectmodal, setopenrejectmodal] = useState(false)
    const [opensnack, setopensnack] = useState(false)
    const [snackmsg, setsnackmsg] = useState(false)

    const [backdrop, setbackdrop] = useState(false);

    const [img, setimg] = useState()

    const [center, setcenter] = useState('')
    const [user, setuser] = useState('')


    // const [offset, setoffset] = useState(0)
    // const [pagecounts, setpagecounts] = useState(0)
    // const [counter, setcounter] = useState()
    // const itemperpage = 10

    const [page, pagechange] = useState(0)
    const [rowPerPage, rowperpagechange] = useState(10)
    const [totalcount, settotalcount] = useState(0)


    useEffect(() => {

        ////console.log("use effect 1")
        // const headers = {
        //   Authorization: KEY,

        // };

        setbackdrop(true)

        const params1 = new URLSearchParams();
        params1.append("offset", page);
        params1.append("pageSize", rowPerPage);
        params1.append("center", center);

        const getmotifs = axios.post(BASE_URL + '/motif/meity/getUploadedMotifsMeity', params1, { withCredentials: true })
        const getcenter = axios.post(BASE_URL + '/getAllCenters',{}, { withCredentials: true })

        Promise.all([getmotifs, getcenter])
            .then(function (responses) {

                //setunmapped(responses[0].data.Beneficiaries)
                setmotifs(responses[0].data.Motifs.content)
                setfmotifs(responses[0].data.Motifs.content)
                setcenters(responses[1].data.Centers)
                settotalcount(responses[0].data.Motifs.totalElements)



                setbackdrop(false)
            }).catch(function (errors) {

        });

    }, [center, page, rowPerPage])

    const handleChangePage = (event, newpage) => {
        pagechange(newpage)
    }

    const handleRowsPerPage = (event) => {
        rowperpagechange(event.target.value)
        pagechange(0)
    }

    // useEffect(() => {
    //   ////console.log("in useeffect 2")
    //   const endOffset = (offset + 1) * itemperpage
    //   setcounter(Math.ceil(motifs.length / itemperpage))
    //   setfmotifs(motifs.slice(offset, endOffset))
    // }, [offset])



    // const handlePageChange = (event, value) => {
    //   setoffset(value)
    // }

    const handleChange = (field) => (event) => {

        const { value } = event.target;

        switch (field) {
            case "center":
                setcenter(value)
                //handleFilterCenter(value)

                break;
            case "user":
                setuser(value)
                //handleFilterUser(value)

                break;

            default:
                break;
        }
    }

    const handleFilterCenter = (center) => {

        ////console.log("cent", center)
        const filterData = motifs.filter((item) => {
            if (item.centerId == center) {
                return item;
            }
        });

        setfmotifs(filterData);

    }
    const handleFilterUser = (center) => {

        ////console.log("cent", center)
        const filterData = motifs.filter((item) => {
            if (item.user.full_name.toLowerCase().includes(center.toLowerCase())) {
                return item;
            }
        });

        setfmotifs(filterData);

    }

    const clearFilters = () => {
        setcenter('')
        //setuser('')
        //setfmotifs(motifs)
    }



    const handleviewclick = (option) => {


        ////console.log("clicked")
        setviewformModal(true)
        ////console.log("options:", option.filename)

        setheight(option.height)
        setwidth(option.width)

        setuploadedby(option.user.full_name)
        setfilename(option.filename)
        setstatus(option.approvalStatus)
        setremarks(option.remarks)
        setmotifid(option.sysMotifId)
    }

    const handleviewformModalClose = () => {

        setviewformModal(false)
    }

    const handleImageclick = (option) => {
        setviewimgModal(true)
        ////console.log("clicked", option)
        setimg(option)

    }

    const handleviewimgModalClose = () => {

        setviewimgModal(false)
    }

    return (
        <>
            <div>
                <NavBarHome
                    title={TITLE}

                />

            </div>
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={Banner1} className="d-block w-100" alt="Banner1" />
                            </div>
                        </div>
                        {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button> */}
                    </div>
                </div>
            </div>
            <h1>
                {/* <Button variant="contained" sx={{ mt: 2, mb: 2 }} startIcon={<AddIcon></AddIcon>} onClick={handleAddProof} >Add Proof Type</Button> */}
                <div className="abc text-black text-center col 10 p-1 mt-1">
                    <strong><h2> View Motifs </h2></strong>
                </div>
            </h1>
            <div className="row mx-5 mb-5" >

                <div className="container " style={{ alignItems: 'center', justifyItems: 'center', display: 'grid' }} >

                    <Grid container spacing={1} sx={{ marginBottom: 4, alignItems: 'center', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6} lg={6}>
                            <div>
                                <TextField

                                    margin="normal"

                                    fullWidth
                                    id="outlined-select-district"
                                    select
                                    label="Center"
                                    value={center}
                                    onChange={
                                        //(e) => setcenter(e.target.value)
                                        handleChange("center")
                                        // setcatid(e.target.value)
                                        // setsubcategories(subcategories.filter(op => op.))

                                        //setdistrict(centers.filter(op => op.center_dist_id === district))


                                    }

                                >
                                    {centers.map((option) => (
                                        <MenuItem
                                            key={option.center_id}
                                            value={option.center_id}
                                        >
                                            {option.center_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Grid>
                        {/* <Grid item xs={12} md={6} lg={6}>
              <div>
                <TextField

                  margin="normal"

                  fullWidth
                  id="outlined-select-district"
                  label="User"
                  value={user}
                  onChange={
                    //(e) =>setuser(e.target.value)
                    handleChange("user")
                    //setdistrict(centers.filter(op => op.center_dist_id === district))

                  }
                >
                </TextField>
              </div>
            </Grid> */}
                        <Grid item xs={12} md={12} lg={12} sx={{ alignItems: 'center', justifyContent: 'center', display: 'grid' }}>
                            <Button variant="text" onClick={clearFilters} >Clear Filters</Button>
                        </Grid>

                    </Grid>

                    <Box sx={{ alignItems: 'center', justifyItems: 'center', width: '100%' }}>
                        <ImageListMotifMeity data={fmotifs} />
                    </Box>
                    <TablePagination
                        sx={{
                            boxShadow: 2,
                            '& .MuiTablePagination-selectLabel': {
                                marginTop: 'auto'
                            },
                            '& .MuiTablePagination-displayedRows': {
                                marginTop: 'auto'
                            },
                            '& .MuiDataGrid-cell': {
                                padding: 0.5
                            },
                            overflowX: 'scroll'


                        }}
                        component="div"
                        count={totalcount}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowPerPage}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        onRowsPerPageChange={handleRowsPerPage}
                    />


                    <Backdrop
                        sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={backdrop}
                        //onClick={handlebackdropClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>


                </div>
            </div>
        </>
    )
}

export default ViewMotifs;