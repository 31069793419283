import React, { useState, useEffect, Fragment, useRef } from "react";
import LoginBanner from "./images/login.jpg";
import NavBarHome from "./NavBar/NavBarHome";
import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import config from "./config.json";
import {
    Routes,
    Route,
    useNavigate,
    Navigate,
    useParams,
} from "react-router-dom";
import { Cookies } from "react-cookie";
import HomePage from "./Home/HomePage";
import Feedback from "./Home/Feedback";
import News from "./Home/News";
import Objective from "./Home/Objective";
import Register from "./Home/Register";
import SADashboard from "./Super Admin/SADashboard";
import UserDashboard from "./User/UserDashboard";
import SADashboard2 from "./Super Admin/SADashboard2";
import regex from "./regex.json";
import ViewDesigns from './Home/ViewDesigns'
import ViewMotifs from './Home/ViewMotifs';
//import makeStyles from '@mui/styles/makeStyles';

import { decrypter } from "./Util";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import { Grid, Snackbar } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { VisibilityOff, Visibility, Close } from "@mui/icons-material";


import UploadMotif from "./User/UploadMotif";
import ManageProofs from "./Super Admin/Master Management/ManageProofs";
import ManageStates from "./Super Admin/Master Management/ManageStates";
import ManageDistrict from "./Super Admin/Master Management/ManageDistrict";
import ViewUserMotifs from "./User/ViewUserMotifs";
import SideBarSuperAdmin from "./SideBar/SideBarSuperAdmin";
import SubDashboard from "./Sub Admin/SubDashboard";

import GlobalStyles from "@mui/material/GlobalStyles";
// Material UI
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ViewMotifsSubAdmin from "./Sub Admin/Motifs/ViewMotifsSubAdmin";
import ApprovedMotifsSubAdmin from "./Sub Admin/Motifs/ApprovedMotifsSubAdmin";
import RejectedMotifsSubAdmin from "./Sub Admin/Motifs/RejectedMotifsSubAdmin";
import ManageProductCategory from "./Super Admin/Master Management/ManageProductCategory";
import ManageProductSubcategory from "./Super Admin/Master Management/ManageProductSubcategory";
import UploadMotifNew from "./User/UploadMotifNew";
import ViewUserMotifsNew from "./User/ViewUserMotifsNew";
import ViewDesignSubAdmin from "./Sub Admin/Designs/ViewDesignSubAdmin";
import ApprovedDesignsSubAdmin from "./Sub Admin/Designs/ApprovedDesignsSubAdmin";
import RejectedDesignsSubAdmin from "./Sub Admin/Designs/RejectedDesignsSubAdmin";
import MeityDashboard from "./Meity/MeityDashboard";
import ViewMotifMeity from "./Meity/ViewMotifMeity";
import ViewDesignMeity from "./Meity/ViewDesignMeity";

import RefreshIcon from "@mui/icons-material/Refresh";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ManageUser from "./Super Admin/UserManagement/ManageUser";
import ManageEmployee from "./Super Admin/UserManagement/ManageEmployee";
import ViewDesignSuperAdmin from "./Super Admin/Motifs/ViewDesignSuperAdmin";
import ViewMotifSuperAdmin from "./Super Admin/Motifs/ViewMotifSuperAdmin";

import * as utils from './Util'
import { el } from "date-fns/locale";
import { useIdleTimer } from 'react-idle-timer'
import IdleTimeoutManager from "./IdleTimeoutManager ";
import Login from "./Login";
import LoginUser from "./LoginUser";


const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const SECRET = config.SECRET;
const TITLE = config.TITLE;
var CryptoJS = require("crypto-js");

const THEME = createTheme({
    typography: {
        fontFamily: `'Ubuntu', sans-serif`,

        fontWeightRegular: 400,
    },
});

function App() {

    const navigate = useNavigate();

    let t1 = sessionStorage.getItem("sessionid")

    var status = ""
    var userrole = ""

    if (t1 != undefined) {
        status = utils.getUserActivated(t1)
        userrole = utils.getRoleNameFromToken(t1)
        //console.log("status token", status)
    }



    // let cookiedata = decrypter(cookies.get("sessionid"));

    // if (cookiedata.loginstatus != undefined) {
    //   //var bytes = CryptoJS.AES.decrypt(cookies.get("loginstatus"), SECRET);
    //   var status = cookiedata.loginstatus;
    // }

    // if (cookies.get("userdata") != undefined) {
    //   var userdata = decrypter(cookies.get("userdata"))

    //   const role = userdata.user.roles.map(op => op.role_id)

    //   var userrole = role[0]
    //   ////console.log("role ", userrole)
    // }

    return (
        <>
            <IdleTimeoutManager>
                <ThemeProvider theme={THEME}>
                    <Routes>
                        <Route path="/" element={<HomePage />}></Route>
                        <Route path="/objective" element={<Objective />}></Route>
                        <Route path="/news" element={<News />}></Route>
                        <Route path="/feedback" element={<Feedback />}></Route>
                        <Route path="/viewDesigns" element={<ViewDesigns />}></Route>
                        <Route path="/viewMotifs" element={<ViewMotifs />}></Route>

                        <Route path="/login" element={(userrole === "SUB ADMIN" ? (<Navigate to="/subadmindashboard"></Navigate>) : (userrole === "SUPER ADMIN" ? (<Navigate to="/admindashboard"></Navigate>) : (userrole === "WEAVER" ? (<Navigate to="/userdashboard"></Navigate>) : (<Login />))))} />
                        <Route path="/loginUser" element={(userrole === "SUB ADMIN" ? (<Navigate to="/subadmindashboard"></Navigate>) : (userrole === "SUPER ADMIN" ? (<Navigate to="/admindashboard"></Navigate>) : (userrole === "WEAVER" ? (<Navigate to="/userdashboard"></Navigate>) : (<LoginUser />))))} />
                        <Route path="/resetPasswordEmployee/:id" element={<ResetPassword />}></Route>
                        <Route path="/resetPasswordUser/:id" element={<ResetPasswordUser />}></Route>
                        <Route path="/adminbase" element={status === "Y" ? (<SideBarSuperAdmin></SideBarSuperAdmin>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/admindashboard" element={status === "Y" ? (<SADashboard></SADashboard>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/userdashboard" element={status === "Y" ? (<UserDashboard></UserDashboard>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/uploadmotif" element={status === "Y" ? (<UploadMotif></UploadMotif>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/uploadmotifnew" element={status === "Y" ? (<UploadMotifNew></UploadMotifNew>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/manageproofs" element={status === "Y" ? (<ManageProofs></ManageProofs>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/managestates" element={status === "Y" ? (<ManageStates></ManageStates>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/managedistricts" element={status === "Y" ? (<ManageDistrict></ManageDistrict>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/viewusermotifs" element={status === "Y" ? (<ViewUserMotifs></ViewUserMotifs>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/viewusermotifsnew" element={status === "Y" ? (<ViewUserMotifsNew></ViewUserMotifsNew>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/viewDesignsSuperAdmin" element={status === "Y" ? (<ViewDesignSuperAdmin></ViewDesignSuperAdmin>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/viewMotifsSuperAdmin" element={status === "Y" ? (<ViewMotifSuperAdmin></ViewMotifSuperAdmin>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/viewdesignSubAdmin" element={status === "Y" ? (<ViewDesignSubAdmin></ViewDesignSubAdmin>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/approveddesignSubAdmin" element={status === "Y" ? (<ApprovedDesignsSubAdmin></ApprovedDesignsSubAdmin>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/rejecteddesignSubAdmin" element={status === "Y" ? (<RejectedDesignsSubAdmin></RejectedDesignsSubAdmin>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/subadmindashboard" element={status === "Y" ? (<SubDashboard></SubDashboard>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/viewmotifsSubAdmin" element={status === "Y" ? (<ViewMotifsSubAdmin></ViewMotifsSubAdmin>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/approvedmotifsSubAdmin" element={status === "Y" ? (<ApprovedMotifsSubAdmin></ApprovedMotifsSubAdmin>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/rejectedmotifsSubAdmin" element={status === "Y" ? (<RejectedMotifsSubAdmin></RejectedMotifsSubAdmin>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/manageproductcategory" element={status === "Y" ? (<ManageProductCategory></ManageProductCategory>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/manageproductsubcategory" element={status === "Y" ? (<ManageProductSubcategory></ManageProductSubcategory>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/meitydashboard" element={status === "Y" ? (<MeityDashboard></MeityDashboard>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/viewmotifsMeity" element={status === "Y" ? (<ViewMotifMeity></ViewMotifMeity>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/viewdesignMeity" element={status === "Y" ? (<ViewDesignMeity></ViewDesignMeity>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/manageUser" element={status === "Y" ? (<ManageUser></ManageUser>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="/manageEmployee" element={status === "Y" ? (<ManageEmployee></ManageEmployee>) : (<Navigate to="/"></Navigate>)}></Route>
                        <Route path="*" element={<ErrorPage />} />
                    </Routes>
                </ThemeProvider>
                <GlobalStyles
                    styles={{
                        "& .MuiTextField-root": {
                            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                                color: "red",
                                "&::after": {},
                            },
                        },
                    }}
                />
            </IdleTimeoutManager>
        </>
    );
}

export default App;


function ResetPassword() {
    const { id } = useParams();

    const navigate = useNavigate();

    const [userpwd, setuserpwd] = useState("");
    const [erruserpwd, seterruserpwd] = useState("");
    const [erruserpwdb, seterruserpwdb] = useState(false);
    const [showuserpwd, setshowuserpwd] = useState(false);

    const [cnfuserpwd, setcnfuserpwd] = useState("");
    const [errcnfuserpwd, seterrcnfuserpwd] = useState("");
    const [errcnfuserpwdb, seterrcnfuserpwdb] = useState(false);
    const [showcnfuserpwd, setshowcnfuserpwd] = useState(false);

    const [opensuccessdialog, setopensuccessdialog] = useState(false);
    const [backdroppwd, setbackdroppwd] = useState(false);

    const [successdialogtitle, setsuccessdialogtitle] = useState("");
    const [successdialogcontent, setsuccessdialogcontent] = useState("");

    const [btndisplay, setbtndisplay] = useState("none")

    const [error, seterror] = useState(false)


    useEffect(() => {

        let KEY = "Bearer " + id;

        const headers = {
            Authorization: KEY,
        };

        axios
            .post(BASE_URL + "/auth/checkToken", {}, {
                headers: headers,
            })
            .then(function (response) {

            })
            .catch(function (error) {

                //console.log("in error: ", error.response)

                if (error.response.status == 500 || error.response.status == 403 || error.response.status == 401) {

                    seterror(true)

                } else {
                    seterror(true)
                }

            });

    }, [])



    const handleClickShowUserPwd = () => {
        if (showuserpwd === false) {
            setshowuserpwd(true);
        } else {
            setshowuserpwd(false);
        }
    };

    const handleClickShowCnfUserPwd = () => {
        if (showcnfuserpwd === false) {
            setshowcnfuserpwd(true);
        } else {
            setshowcnfuserpwd(false);
        }
    };

    const handlesuccessdialogclose = () => {
        setopensuccessdialog(false);
    };
    const handleSuccessSubmit = () => {
        setopensuccessdialog(false);
    };

    const handlePwdSubmit = () => {
        if (validation()) {
            ////console.log("success")

            setbackdroppwd(true);

            let KEY = "Bearer " + id;

            const headers = {
                Authorization: KEY,
            };

            const md5 = require("md5");

            const params1 = new URLSearchParams();
            params1.append("pwd", cnfuserpwd);

            axios
                .post(BASE_URL + "/auth/changePasswordEmp", params1, {
                    headers: headers,
                })
                .then(function (response) {
                    ////console.log("response ", response)
                    ////console.log("status ", response)
                    setbackdroppwd(false);

                    if (response.status == 200) {
                        ////console.log("in 200")

                        if (response.data === "Updated") {
                            setsuccessdialogtitle("Password Reset Successful");
                            setsuccessdialogcontent(
                                "Password has been reset.. You will be re directed to homepage "
                            );
                            setopensuccessdialog(true);

                            setTimeout(function () {
                                navigate("/");
                            }, 2000);
                        } else if (response.data === "Error") {
                            setsuccessdialogtitle("Error");
                            setsuccessdialogcontent(
                                "Something Went Wrong.. Please try again later"
                            );
                            setopensuccessdialog(true);

                            setTimeout(function () {
                                navigate("/");
                            }, 2000);
                        } else if (response.data === "New Password Cannot Be Same as Old Password") {
                            setsuccessdialogtitle("Error");
                            setsuccessdialogcontent(
                                "New Password Cannot Be Same as Old Password"
                            );
                            setbtndisplay("block")
                            setopensuccessdialog(true);
                        }
                    } else {
                        ////console.log("in else")
                        setbackdroppwd(false);
                        setsuccessdialogtitle("Error");
                        setsuccessdialogcontent(
                            "Something Went Wrong.. Please try again later"
                        );
                        setopensuccessdialog(true);

                        setTimeout(function () {
                            navigate("/");
                        }, 2000);
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401 || error.response.status == 403) {
                        setbackdroppwd(false);

                        setsuccessdialogtitle("Link Expired");
                        setsuccessdialogcontent(
                            "Password reset link has been expired.. Please go to forgot password menu in login page "
                        );
                        setopensuccessdialog(true);
                        setTimeout(function () {
                            navigate("/");
                        }, 3000);
                    } else {
                        setbackdroppwd(false);

                        setsuccessdialogtitle("Error");
                        setsuccessdialogcontent(
                            "Something Went Wrong..Please try again later "
                        );
                        setopensuccessdialog(true);
                        setTimeout(function () {
                            navigate("/");
                        }, 3000);
                    }
                });
        }
    };

    function validation() {
        let isValid = true;

        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/

        if (userpwd === "") {
            seterruserpwdb(true);
            seterruserpwd("Password Cannot be Blank");
            isValid = false;
        } else if (passwordRegex.test(userpwd) === false) {
            seterruserpwdb(true);
            seterruserpwd("Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.");
            isValid = false;
        }
        else {
            seterruserpwdb(false);
            seterruserpwd("");
        }

        if (cnfuserpwd === "") {
            seterrcnfuserpwdb(true);
            seterrcnfuserpwd("Password Cannot be Blank");
            isValid = false;
        } else if (passwordRegex.test(cnfuserpwd) === false) {
            seterrcnfuserpwd(true);
            seterruserpwd("Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.");
            isValid = false;
        }
        else {
            seterrcnfuserpwdb(false);
            seterrcnfuserpwd("");
        }

        if (userpwd !== cnfuserpwd) {
            seterrcnfuserpwdb(true);
            seterrcnfuserpwd("Passwords must match");
            isValid = false;
        } else {
            seterrcnfuserpwdb(false);
            seterrcnfuserpwd("");
        }

        return isValid;
    }

    const handleClose = () => {
        setopensuccessdialog(false);
        setbtndisplay("none")
    }

    return error ? (<ErrorPage></ErrorPage>) : (<>


        {/* carousel starts */}
        <div className="row">
            <div className="container ">
                <div
                    id="carouselExampleIndicators"
                    className="carousel slide carousel-fade"
                    data-bs-ride="carousel"
                >
                    <div className="carousel-indicators">
                        <button
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to="0"
                            className="active"
                            aria-current="true"
                            aria-label="Slide 1"
                        ></button>
                        {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={LoginBanner} className="d-block w-100" alt="image1" />
                        </div>
                    </div>
                    <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="prev"
                    >
                        <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                        ></span>
                        <span className="sr-only">Previous</span>
                    </button>
                    <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="next"
                    >
                        <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                        ></span>
                        <span className="sr-only">Next</span>
                    </button>
                </div>
            </div>
        </div>
        {/* carousel ends */}
        <div className="row mx-5 mb-5 mt-5 ">
            <div className="container col-md-4  ">
                <Box
                    sx={{
                        marginTop: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        boxShadow: 5,
                        marginTop: 1,
                        background: "#fafafa",
                        padding: 5,
                    }}
                >
                    <h1>
                        <div className=" text-black text-center col 10 p-1">
                            <strong>
                                <h2> Reset Password </h2>
                            </strong>
                        </div>
                    </h1>

                    <Box justifyContent="center" sx={{ height: "100%", width: "100%" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div>
                                    <TextField
                                        error={erruserpwdb}
                                        helperText={erruserpwd}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="outlined-name"
                                        label="Password"
                                        autoComplete="off"
                                        //defaultValue={userdata.state_name}
                                        value={userpwd}
                                        type={showuserpwd ? "text" : "password"}
                                        onChange={(e) => setuserpwd(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowUserPwd}
                                                        //onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showuserpwd ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    ></TextField>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div>
                                    <TextField
                                        error={errcnfuserpwdb}
                                        helperText={errcnfuserpwd}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="outlined-name"
                                        label="Confirm Password"
                                        autoComplete="off"
                                        //defaultValue={userdata.state_name}
                                        value={cnfuserpwd}
                                        type={showcnfuserpwd ? "text" : "password"}
                                        onChange={(e) => setcnfuserpwd(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowCnfUserPwd}
                                                        //onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showcnfuserpwd ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    ></TextField>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" sx={{ mt: 3 }}>
                            <Button variant="contained" onClick={handlePwdSubmit}>
                                Submit
                            </Button>
                        </Grid>
                    </Box>
                </Box>
            </div>
        </div>
        {/* Success password dialog*/}
        <Dialog
            open={opensuccessdialog}
            sx={{
                "& .MuiDialog-paper": {
                    width: "500px",
                },
            }}
        >
            <DialogTitle>{successdialogtitle}</DialogTitle>
            <DialogContent>{successdialogcontent}</DialogContent>
            <DialogActions>
                <Button style={{ display: btndisplay }} onClick={handleClose}>Dismiss</Button>
            </DialogActions>
        </Dialog>
        {/* Success password dialog*/}
        <Backdrop
            sx={{ color: "#ffffff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdroppwd}
        //onClick={handlebackdropClose}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>)

}

function ResetPasswordUser() {
    const { id } = useParams();

    const navigate = useNavigate();

    const [userpwd, setuserpwd] = useState("");
    const [erruserpwd, seterruserpwd] = useState("");
    const [erruserpwdb, seterruserpwdb] = useState(false);
    const [showuserpwd, setshowuserpwd] = useState(false);

    const [cnfuserpwd, setcnfuserpwd] = useState("");
    const [errcnfuserpwd, seterrcnfuserpwd] = useState("");
    const [errcnfuserpwdb, seterrcnfuserpwdb] = useState(false);
    const [showcnfuserpwd, setshowcnfuserpwd] = useState(false);

    const [opensuccessdialog, setopensuccessdialog] = useState(false);
    const [backdroppwd, setbackdroppwd] = useState(false);

    const [successdialogtitle, setsuccessdialogtitle] = useState("");
    const [successdialogcontent, setsuccessdialogcontent] = useState("");

    const [btndisplay, setbtndisplay] = useState("none")

    const [error, seterror] = useState(false)


    useEffect(() => {

        let KEY = "Bearer " + id;

        const headers = {
            Authorization: KEY,
        };

        axios
            .post(BASE_URL + "/auth/checkToken", {}, {
                headers: headers,
            })
            .then(function (response) {

            })
            .catch(function (error) {

                //console.log("in error: ", error.response)

                if (error.response.status == 500 || error.response.status == 403 || error.response.status == 401) {

                    seterror(true)

                } else {
                    seterror(true)
                }

            });

    }, [])

    const handleClickShowUserPwd = () => {
        if (showuserpwd === false) {
            setshowuserpwd(true);
        } else {
            setshowuserpwd(false);
        }
    };

    const handleClickShowCnfUserPwd = () => {
        if (showcnfuserpwd === false) {
            setshowcnfuserpwd(true);
        } else {
            setshowcnfuserpwd(false);
        }
    };

    const handlesuccessdialogclose = () => {
        setopensuccessdialog(false);
    };
    const handleSuccessSubmit = () => {
        setopensuccessdialog(false);
    };

    const handlePwdSubmit = () => {
        if (validation()) {
            ////console.log("success")

            setbackdroppwd(true);

            let KEY = "Bearer " + id;

            const headers = {
                Authorization: KEY,
            };

            const md5 = require("md5");

            const params1 = new URLSearchParams();
            params1.append("pwd", cnfuserpwd);

            axios
                .post(BASE_URL + "/auth/changePasswordUser", params1, {
                    headers: headers,
                })
                .then(function (response) {
                    ////console.log("response ", response)
                    ////console.log("status ", response)
                    setbackdroppwd(false);

                    if (response.status == 200) {
                        ////console.log("in 200")

                        if (response.data === "Updated") {
                            setsuccessdialogtitle("Password Reset Successful");
                            setsuccessdialogcontent(
                                "Password has been reset.. You will be re directed to homepage "
                            );
                            setopensuccessdialog(true);

                            setTimeout(function () {
                                navigate("/");
                            }, 2000);
                        } else if (response.data === "Error") {
                            setsuccessdialogtitle("Error");
                            setsuccessdialogcontent(
                                "Something Went Wrong.. Please try again later"
                            );
                            setopensuccessdialog(true);

                            setTimeout(function () {
                                navigate("/");
                            }, 2000);
                        } else if (response.data === "New Password Cannot Be Same as Old Password") {
                            setsuccessdialogtitle("Error");
                            setsuccessdialogcontent(
                                "New Password Cannot Be Same as Old Password"
                            );
                            setbtndisplay("block")
                            setopensuccessdialog(true);
                        }
                    } else {
                        ////console.log("in else")
                        setbackdroppwd(false);
                        setsuccessdialogtitle("Error");
                        setsuccessdialogcontent(
                            "Something Went Wrong.. Please try again later"
                        );
                        setopensuccessdialog(true);

                        setTimeout(function () {
                            navigate("/");
                        }, 2000);
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401 || error.response.status == 403) {
                        setbackdroppwd(false);

                        setsuccessdialogtitle("Link Expired");
                        setsuccessdialogcontent(
                            "Password reset link has been expired.. Please go to forgot password menu in login page "
                        );
                        setopensuccessdialog(true);
                        setTimeout(function () {
                            navigate("/");
                        }, 3000);
                    } else {
                        setbackdroppwd(false);

                        setsuccessdialogtitle("Error");
                        setsuccessdialogcontent(
                            "Something Went Wrong..Please try again later "
                        );
                        setopensuccessdialog(true);
                        setTimeout(function () {
                            navigate("/");
                        }, 3000);
                    }
                });
        }
    };

    function validation() {
        let isValid = true;

        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/

        if (userpwd === "") {
            seterruserpwdb(true);
            seterruserpwd("Password Cannot be Blank");
            isValid = false;
        } else if (passwordRegex.test(userpwd) === false) {
            seterruserpwdb(true)
            seterruserpwd("Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.")
            isValid = false
        }
        else {
            seterruserpwdb(false);
            seterruserpwd("");
        }

        if (cnfuserpwd === "") {
            seterrcnfuserpwdb(true);
            seterrcnfuserpwd("Password Cannot be Blank");
            isValid = false;
        } else if (passwordRegex.test(cnfuserpwd) === false) {
            seterrcnfuserpwdb(true)
            seterrcnfuserpwd("Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.")
            isValid = false
        }
        else {
            seterrcnfuserpwdb(false);
            seterrcnfuserpwd("");
        }

        if (userpwd !== cnfuserpwd) {
            seterrcnfuserpwdb(true);
            seterrcnfuserpwd("Passwords must match");
            isValid = false;
        } else {
            seterrcnfuserpwdb(false);
            seterrcnfuserpwd("");
        }

        return isValid;
    }


    const handleClose = () => {
        setopensuccessdialog(false);
        setbtndisplay("none")
    }

    return error ? (<ErrorPage></ErrorPage>) : (
        <>
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div
                        id="carouselExampleIndicators"
                        className="carousel slide carousel-fade"
                        data-bs-ride="carousel"
                    >
                        <div className="carousel-indicators">
                            <button
                                type="button"
                                data-bs-target="#carouselExampleIndicators"
                                data-bs-slide-to="0"
                                className="active"
                                aria-current="true"
                                aria-label="Slide 1"
                            ></button>
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={LoginBanner} className="d-block w-100" alt="image1" />
                            </div>
                        </div>
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="prev"
                        >
                            <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                            ></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="next"
                        >
                            <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                            ></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="row mx-5 mb-5 mt-5 ">
                <div className="container col-md-4  ">
                    <Box
                        sx={{
                            marginTop: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            boxShadow: 5,
                            marginTop: 1,
                            background: "#fafafa",
                            padding: 5,
                        }}
                    >
                        <h1>
                            <div className=" text-black text-center col 10 p-1">
                                <strong>
                                    <h2> Reset Password </h2>
                                </strong>
                            </div>
                        </h1>

                        <Box justifyContent="center" sx={{ height: "100%", width: "100%" }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div>
                                        <TextField
                                            error={erruserpwdb}
                                            helperText={erruserpwd}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="outlined-name"
                                            label="Password"
                                            autoComplete="off"
                                            //defaultValue={userdata.state_name}
                                            value={userpwd}
                                            type={showuserpwd ? "text" : "password"}
                                            onChange={(e) => setuserpwd(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowUserPwd}
                                                            //onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showuserpwd ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        ></TextField>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        <TextField
                                            error={errcnfuserpwdb}
                                            helperText={errcnfuserpwd}
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="outlined-name"
                                            label="Confirm Password"
                                            autoComplete="off"
                                            //defaultValue={userdata.state_name}
                                            value={cnfuserpwd}
                                            type={showcnfuserpwd ? "text" : "password"}
                                            onChange={(e) => setcnfuserpwd(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowCnfUserPwd}
                                                            //onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showcnfuserpwd ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        ></TextField>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" sx={{ mt: 3 }}>
                                <Button variant="contained" onClick={handlePwdSubmit}>
                                    Submit
                                </Button>
                            </Grid>
                        </Box>
                    </Box>
                </div>
            </div>
            {/* Success password dialog*/}
            <Dialog
                open={opensuccessdialog}
                sx={{
                    "& .MuiDialog-paper": {
                        width: "500px",
                    },
                }}
            >
                <DialogTitle>{successdialogtitle}</DialogTitle>
                <DialogContent>{successdialogcontent}</DialogContent>
                <DialogActions>
                    <Button style={{ display: btndisplay }} onClick={handleClose}>Dismiss</Button>
                </DialogActions>
            </Dialog>
            {/* Success password dialog*/}
            <Backdrop
                sx={{ color: "#ffffff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdroppwd}
            //onClick={handlebackdropClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

function ErrorPage() {

    const navigate = useNavigate();


    return (
        <>
            <NavBarHome title={TITLE}></NavBarHome>


            <section className="py-3 py-md-5 min-vh-100 d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="text-center">
                                <h2 className="d-flex justify-content-center align-items-center gap-2 mb-4">
                                    <span className="display-1 fw-bold">4</span>
                                    <span className="display-1 fw-bold">0</span>
                                    <span className="display-1 fw-bold bsb-flip-h">4</span>
                                </h2>
                                <h3 className="h2 mb-2">Oops! You're lost.</h3>
                                <p className="mb-5">The page you are looking for was not found.</p>
                                <a className="btn bsb-btn-5xl btn-dark rounded-pill px-5 fs-6 m-0" href="/" role="button" >Back to Home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}
